import { useState } from "react";
import { InputField, MainButton, Loader } from "commonUI";
import "./auth.css";
import { Logo } from "allIcons";
import { printError, useSearch, useUtilities } from "helpers";
import { ApiResponseType } from "enums";
import axios from "axios";

export default function CreatePassword() {
  const { navigate } = useUtilities();
  const { rt, id } = useSearch();
  const [userInput, setUserInput] = useState({
    password: "",
    confirm_password: "",
    error: "",
    sucess: "",
  });
  const [loading, setLoading] = useState(false);
  function handleForget(e) {
    e.preventDefault();
    setLoading(true);
    if (!userInput.password) {
      setUserInput({
        ...userInput,
        error: "Please enter password",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    if (userInput.password.length < 6) {
      setUserInput({
        ...userInput,
        error: "Password must be at least 6 characters",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    if (!userInput.confirm_password) {
      setUserInput({
        ...userInput,
        error: "Please enter confirm password",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    if (userInput.password !== userInput.confirm_password) {
      setUserInput({
        ...userInput,
        error: "Password and confirm password not match",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}creatememberpassword`, {
        password: userInput.password,
        id: Number(id),
        rt,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          setUserInput({
            ...userInput,
            sucess: "Password created successfully",
            error: "",
          });
          navigate('/login')
        } else {
          setUserInput({
            ...userInput,
            error: "session expired",
            sucess: "",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUserInput({
          ...userInput,
          error: printError(err.message),
          sucess: "",
        });
        setLoading(false);
      });
  }
  return (
    <>
      {loading && <Loader />}
      <div className={`login-container`}>
        <div className="">
          <div
            className={`flex items-center bg-transparent mb-12 justify-center`}
          >
            <Logo className="logo-size" />
          </div>
          <div className="login-form">
            <h2 className="login-heading">Create Password</h2>
            {/* <p className="body-text forget-details">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </p> */}
            <div className="py-4 w-fit mx-auto">
              {userInput.error ? (
                <p className="error-text mb-1 mt-0">{userInput.error}</p>
              ) : (
                userInput.sucess && (
                  <p className="success-text mb-1 mt-0">{userInput.sucess}</p>
                )
              )}
              <InputField
                type="password"
                name="password"
                id="password"
                placeholder="Write Password Here"
                value={userInput.password}
                setValue={(value) =>
                  setUserInput({ ...userInput, password: value })
                }
                inputClass="py-1"
              />
              <InputField
                type="password"
                name="confirm_password"
                id="confirm_password"
                placeholder="Write Confirm Password Here"
                value={userInput.confirm_password}
                setValue={(value) =>
                  setUserInput({ ...userInput, confirm_password: value })
                }
                inputClass="py-1"
              />
              <div className="py-2">
                <MainButton onClick={handleForget} className={`w-full py-2 `}>
                  Create
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
