import React, { useEffect, useState } from "react";
import { SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  LimitReachedCard,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  SelectField,
  ToggleCard,
} from "commonUI";
import { useSmtp, useUpdateSmtpSetting } from "hooks/smtp";
import { isEqual, parse, useSearch } from "helpers";
import useUserPlan from "hooks/plan";
import { planTypes } from "enums/plans";
import { useUserPermissions } from "hooks/user";

export default function SMTP() {
  const { data, isLoading } = useSmtp();
  const { web = null } = useSearch();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const whiteLabelAllowed = isEqual(wpslPlan.white_label, 1);
  const { mutate: updateSmtp, isLoading: updatingSmtp } =
    useUpdateSmtpSetting();
  const [values, setValues] = useState({
    smtp_host: "",
    smtp_from_email: "",
    smtp_from_name: "",
    encryption: "None",
    smtp_port: "",
    auto_tls: false,
    authentication: true,
    smtp_username: "",
    smtp_password: "",
    smtp_custom: false,
  });

  useEffect(() => {
    if (!data || isLoading) return;
    const smtp = data?.find((item) => item.website_id === (web || null));
    if (!smtp) return;
    setValues(parse(smtp.smtp_settings) || values);
  }, [data, isLoading, web]);

  const handleSaveChanges = () => {
    updateSmtp({
      website_id: web || null,
      data: values,
    });
  };
  const { add_new_items_to_site_template, edit_item_details_in_site_template } =
    useUserPermissions();

  return (
    <RightMainContainer>
      <Loader show={updatingSmtp} loader="block" />
      <HeaderContainer>
        <PrimaryHeading>SMTP</PrimaryHeading>
        {(!whiteLabelAllowed ||
          !add_new_items_to_site_template ||
          !edit_item_details_in_site_template) &&
        !loadingPlans ? (
          ""
        ) : (
          <MainButton
            onClick={handleSaveChanges}
            disabled={updatingSmtp || isLoading}
          >
            <SaveIcon />
            Save Changes
          </MainButton>
        )}
      </HeaderContainer>
      <BodyContainer>
        {!whiteLabelAllowed && !loadingPlans ? (
          <LimitReachedCard
            show
            title="Custom SMTP Is Not Included"
            description="Please note that access to the SMTP feature is not included in your current plan. To utilize this feature, consider upgrading your account"
          />
        ) : (
          <>
            <ToggleCard
              label="Use Custom SMTP Settings"
              description="All emails related to the WP SiteLauncher system are handled by our own SMTP server. However, if you want to customize the SMTP details, you can choose to enable this setting and add your SMTP details instead."
              value={values.smtp_custom}
              setValue={(value) => setValues({ ...values, smtp_custom: value })}
            />
            {values.smtp_custom ? (
              <>
                <InputField
                  label="SMTP Host"
                  placeholder="Write SMTP Host Here"
                  value={values.smtp_host}
                  setValue={(value) =>
                    setValues({ ...values, smtp_host: value })
                  }
                />
                <InputField
                  label="SMTP From Email"
                  placeholder="Write SMTP From Email Here"
                  value={values.smtp_from_email}
                  setValue={(value) =>
                    setValues({ ...values, smtp_from_email: value })
                  }
                />
                <InputField
                  label="SMTP From Name"
                  placeholder="Write SMTP From Name Here"
                  value={values.smtp_from_name}
                  setValue={(value) =>
                    setValues({ ...values, smtp_from_name: value })
                  }
                />
                <SelectField
                  label="Encryption"
                  placeholder="None"
                  value={values.encryption}
                  setValue={(value) =>
                    setValues({ ...values, encryption: value })
                  }
                >
                  <option value="None">None</option>
                  <option value="TLS">TLS</option>
                  <option value="SSL">SSL</option>
                </SelectField>
                <InputField
                  label="SMTP Port"
                  placeholder="Write SMTP Port Here"
                  value={values.smtp_port}
                  setValue={(value) =>
                    setValues({ ...values, smtp_port: value })
                  }
                />
                <div>
                  <SecondaryHeading>Auto TLS</SecondaryHeading>
                  <ToggleCard
                    value={values.auto_tls}
                    setValue={(value) =>
                      setValues({ ...values, auto_tls: value })
                    }
                  />
                </div>
                <div className="mt-3 mb-3">
                  <SecondaryHeading>Authentication</SecondaryHeading>
                  <ToggleCard
                    value={values.authentication}
                    setValue={(value) =>
                      setValues({ ...values, authentication: value })
                    }
                  />
                </div>
                <InputField
                  label="SMTP Username"
                  placeholder="Write SMTP Username Here"
                  value={values.smtp_username}
                  setValue={(value) =>
                    setValues({ ...values, smtp_username: value })
                  }
                />
                <InputField
                  label="SMTP Password"
                  placeholder="Write SMTP Password Here"
                  value={values.smtp_password}
                  setValue={(value) =>
                    setValues({ ...values, smtp_password: value })
                  }
                  type="password"
                />
              </>
            ) : (
              ""
            )}
          </>
        )}
      </BodyContainer>
    </RightMainContainer>
  );
}
