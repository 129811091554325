import { DeleteIcon } from "allIcons";
import { DeleteModal, MainButton, SecondaryHeading } from "commonUI";
import { useState } from "react";

export default function DeleteButton({
  show = true,
  onDelete,
  deleteMessage,
  deleteTitle = "Are You Sure?",
  deleteConfirmations,
  deleteButtonText = "Confirm",
  type = "icon",
  className = "",
  title = "Delete",
  message = "If you no longer need this data, you can choose to delete it from your account. This will permanently remove the data and all of its associated data.",
  buttonText = "Delete",
  position = "bottom",
  style = {},
  toolTip = {},
  isDeleteButton = true,
  onClose = () => {},
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  return (
    <>
      {type !== "icon" ? (
        <div className={className ?? ""}>
          <SecondaryHeading>{title}</SecondaryHeading>
          <p className="body-text mb-2 mt-1-5">{message}</p>
        </div>
      ) : (
        ""
      )}
      {show ? (
        <div className="relative">
          {type === "icon" ? (
            <DeleteIcon
              onClick={() => setOpenDeleteModal(true)}
              className="cursor-pointer"
            />
          ) : (
            <MainButton
              className="delete-button-red"
              onClick={() => setOpenDeleteModal(true)}
            >
              <DeleteIcon fill="white" />
              {buttonText}
            </MainButton>
          )}
          <DeleteModal
            position={position}
            style={style}
            toolTip={toolTip}
            setOpen={setOpenDeleteModal}
            open={openDeleteModal}
            isDeleteButton={isDeleteButton}
            deleteMessage={
              deleteMessage ??
              "You are about to delete Content Request Item from your account. This action cannot be undone! All settings related to the WP SiteLauncher Content Request Item will permanently removed. Do you wish to proceed and delete the Content Request Item?"
            }
            deleteTitle={deleteTitle}
            deleteButtonText={deleteButtonText}
            deleteConfirmations={deleteConfirmations}
            onDelete={onDelete}
            onClose={onClose}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
