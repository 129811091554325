import {
  ListCard,
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  MainButton,
  BodyContainer,
  ListIconWrapper,
  InputField,
  PopOver,
  PopUpListing,
  AddFromTemplateLibrary,
  Loader,
  LoadingListCards,
  TemplateNotFound,
  FiltersList,
} from "commonUI";
import userPic from "assets/pic.png";
import { AddIcon, CloudDownloadIcon } from "allIcons";
import {
  useAddDefaultMember,
  useAddUsertoSingleWeb,
  useAddUsertoWP,
  useDefaultMembers,
  useMembers,
  useRemoveDefaultMember,
  useRemoveMemberFromWeb,
  useSiteRelateUsers,
} from "api/usersModule/members";
import { useDeferredValue, useEffect, useState } from "react";
import { isEqual, parse, search, useSearch, useUtilities } from "helpers";
import { useRoles } from "api/usersModule/roles";
import { useSites } from "api/sites";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";
import { useUserLimitReached } from "hooks/plan";

export default function UserTab() {
  const { web = null } = useSearch();
  const { data } = useSites();
  const siteDetails = data?.site_details?.total_sites?.find((site) =>
    isEqual(site.dl_website_id, web)
  );
  const { data: members, isLoading: loadingMembers } = useMembers();
  const { data: users, isLoading, error } = useDefaultMembers();
  const { data: siteUsers, isLoading: loadingSiteUsers } = useSiteRelateUsers();
  const { data: roles, isLoading: loadingRoles } = useRoles();
  const { mutateAsync: addUsertoWP, isLoading: addingUserToWP } =
    useAddUsertoWP();
  const { mutateAsync: addDefaultMember, isLoading: isAdding } =
    useAddDefaultMember();
  const { mutate: removeDefaultMember, isLoading: isRemoving } =
    useRemoveDefaultMember();
  const { mutateAsync: addtoSingleWebsite, isLoading: addingUsertoWeb } =
    useAddUsertoSingleWeb();
  const { mutateAsync: removeFromSingleWebsite, isLoading: removingUsertoWeb } =
    useRemoveMemberFromWeb();
  const { navigate } = useUtilities();
  const {
    add_new_items_to_site_template,
    add_items_from_template_library,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  const [membersAndClientList, setMembersAndClientList] = useState({
    members: [],
    clients: [],
  });

  const { isLimitReached } = useUserLimitReached();
  // const webMembers = users?.filter((ele) => {
  //   let parsedContent = ele?.website_id?.includes("[")
  //     ? parse(ele.website_id)
  //     : ele.website_id;
  //   if (Array.isArray(parsedContent)) {
  //     return parsedContent?.includes(web);
  //   } else {
  //     return false;
  //   }
  // });

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    if (!isLoading) {
      setMembersAndClientList({
        members: (web ? siteUsers : members).filter(
          (ele) =>
            isEqual(ele.user_type, "Team Member") &&
            (search(`${ele.name} ${ele.last_name}`, defferedSearch) || search(ele.email, defferedSearch)) &&
            (!web ? isEqual(ele.default_tem, "1") : true)
        ),
        clients: (web ? siteUsers : members).filter(
          (ele) =>
            isEqual(ele.user_type, "Client") &&
            (search(`${ele.name} ${ele.last_name}`, defferedSearch) || search(ele.email, defferedSearch)) &&
            (!web ? isEqual(ele.default_tem, "1") : true)
        ),
      });
    }
  }, [isLoading, users, defferedSearch, members, web]);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);

  const applySorting = (oder, orderBy) => {
    setMembersAndClientList({
      members: libraryItemSorting(
        oder,
        orderBy,
        membersAndClientList.members,
        "name"
      ),
      clients: libraryItemSorting(
        oder,
        orderBy,
        membersAndClientList.clients,
        "name"
      ),
    });
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setMembersAndClientList({
      members: libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        (web ? siteUsers : users).filter((ele) =>
          isEqual(ele.user_type, "Team Member")
        ),
        "user_role"
      ),
      clients: libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        (web ? siteUsers : users).filter((ele) =>
          isEqual(ele.user_type, "Client")
        ),
        "user_role"
      ),
    });
  };

  return (
    <>
      <RightMainContainer>
        <Loader
          show={
            isAdding ||
            isRemoving ||
            addingUserToWP ||
            addingUsertoWeb ||
            removingUsertoWeb
          }
          loader="block"
        />
        <HeaderContainer>
          <PrimaryHeading>Users</PrimaryHeading>
          <div className={"relative"}>
            {add_new_items_to_site_template ? (
              <MainButton
                onClick={() => {
                  // if (web) {
                  //   setAddUserModal(true);
                  //   return;
                  // }
                  setOpenAddModal(true);
                }}
              >
                <AddIcon />
                Add
              </MainButton>
            ) : (
              ""
            )}
            <PopOver
              toolTip={{ vertical: "top", right: "30px" }}
              open={openAddModal || addUserModal}
              onClose={() => {
                setOpenAddModal(false);
                setAddUserModal(false);
              }}
              style={{
                top: "calc(100% + 10px)",
                right: "0",
                bottom: "auto",
              }}
            >
              {addUserModal ? (
                <AddFromTemplateLibrary
                  heading="Add User From User Library"
                  isLoading={loadingMembers}
                  data={members
                    .filter((ele) => {
                      if (web) {
                        let parsedContent = ele?.website_id?.includes("[")
                          ? parse(ele.website_id)
                          : ele.website_id;
                        return !parsedContent?.includes(web);
                      } else {
                        return !isEqual(ele.default_tem, "1");
                      }
                    })
                    .map((item) => ({
                      ...item,
                      title: item.name + " " + item.last_name,
                      onClick: () => {
                        if (web) {
                          addtoSingleWebsite({
                            user_id: item.id,
                            website_id: web,
                          }).then((data) => {
                            if (data.Status_Code === 200 && siteDetails) {
                              addUsertoWP({
                                web_url: siteDetails.web_url,
                                email: item.email,
                                first_name: item.name,
                                last_name: item.last_name,
                                wpsl_user_id: item.id,
                                "HTTP-DL-WEBSITE-KEY":
                                  siteDetails.dl_website_id,
                              });
                            }
                          });
                        } else {
                          addDefaultMember({
                            data: { id: item.id, website_id: web },
                          });
                        }
                      },
                      icon: (
                        <ListIconWrapper className="bg-white">
                          <img
                            src={userPic}
                            className="avatar"
                            alt="user avatar"
                          />
                        </ListIconWrapper>
                      ),
                    }))}
                  emptyTemplate={{
                    label: "User",
                    linkLabel: "User",
                    link: isLimitReached
                      ? ""
                      : `/site-settings/users/add${web ? `?web=${web}` : ""}`,
                  }}
                />
              ) : (
                <PopUpListing
                  data={[
                    add_items_from_template_library && {
                      title: "From Account",
                      icon: <CloudDownloadIcon />,
                      onClick: () => {
                        setAddUserModal(true);
                      },
                      arrow: true,
                    },
                    isLimitReached
                      ? null
                      : {
                          title: "Create New User",
                          icon: <AddIcon />,
                          onClick: () => {
                            navigate(
                              `/site-settings/users/add${
                                web ? `?web=${web}` : ""
                              }`
                            );
                          },
                        },
                  ].filter((_) => _)}
                />
              )}
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            searchIcon
            filter
            placeholder="Search"
            showTags={false}
            value={searchText}
            setValue={setSearchText}
            filterList={
              roles?.map((_) => ({
                id: _.id,
                title: _.role_title,
                user_role: _.id,
              })) ?? []
            }
            filterData={filterData}
            filterTitle={"User Role"}
            filterAddTitle={"Add Role"}
            applySorting={applySorting}
            onApplyFilter={applyFilter}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading || loadingSiteUsers ? (
            <LoadingListCards />
          ) : membersAndClientList.members.length === 0 &&
            membersAndClientList.clients.length === 0 ? (
            <TemplateNotFound
              label="User"
              linkLabel={isLimitReached ? "" : "User"}
              link={
                isLimitReached
                  ? ""
                  : `/site-settings/users/add${web ? `?web=${web}` : ""}`
              }
            />
          ) : (
            ""
          )}
          {membersAndClientList.members.length > 0 ? (
            <PrimaryHeading className="text-17">Team Members</PrimaryHeading>
          ) : (
            ""
          )}
          {membersAndClientList.members.map((item, index) => {
            return (
              <ListCard
                data={`${item.name} ${item.last_name}`}
                editLink={
                  edit_item_details_in_site_template
                    ? `/site-settings/users/${item.id}`
                    : ""
                }
                link={
                  edit_item_details_in_site_template
                    ? `/site-settings/users/${item.id}`
                    : ""
                }
                strictLink
                backUrlOnEdit={false}
                deleteIcon
                crossIcon={remove_items_from_site_template}
                editIcon={edit_item_details_in_site_template}
                onCross={() => {
                  if (web) {
                    removeFromSingleWebsite({
                      user_id: item.id,
                      website_id: web,
                    });
                  } else {
                    removeDefaultMember(item.id);
                  }
                }}
                secondaryData={
                  item?.roles?.role_title ??
                  roles.find((_) => isEqual(_.id, item.user_role))
                    ?.role_title ??
                  ""
                }
                icon={
                  <ListIconWrapper className="bg-white">
                    <img src={userPic} className="avatar" alt="user avatar" />
                  </ListIconWrapper>
                }
                showModalonCross
                deleteTitle={"Remove This User?"}
                deleteButtonText={"Confirm"}
                deleteMessage={
                  web
                    ? "You are about to remove this user from your site. Are you sure you want to do this? (This will not delete the WordPress user.)"
                    : "You are about to remove this user from your default site template. Are you sure you want to do this? (This will not delete the user from your account.)"
                }
              />
            );
          })}

          {membersAndClientList.clients.length > 0 ? (
            <PrimaryHeading className="text-17">Clients</PrimaryHeading>
          ) : (
            ""
          )}
          {membersAndClientList.clients.map((item, index) => {
            return (
              <ListCard
                data={`${item.name} ${item.last_name}`}
                editLink={
                  edit_item_details_in_site_template
                    ? `/site-settings/users/${item.id}`
                    : ""
                }
                link={
                  edit_item_details_in_site_template
                    ? `/site-settings/users/${item.id}`
                    : ""
                }
                backUrlOnEdit={false}
                deleteIcon
                crossIcon={remove_items_from_site_template}
                editIcon={edit_item_details_in_site_template}
                onCross={() => {
                  if (web) {
                    removeFromSingleWebsite({
                      user_id: item.id,
                      website_id: web,
                    });
                  } else {
                    removeDefaultMember(item.id);
                  }
                }}
                secondaryData={
                  item?.roles?.role_title ??
                  roles.find((_) => isEqual(_.id, item.user_role))
                    ?.role_title ??
                  ""
                }
                icon={
                  <ListIconWrapper className="bg-white">
                    <img src={userPic} className="avatar" alt="user avatar" />
                  </ListIconWrapper>
                }
                showModalonCross
                deleteTitle={"Remove This User?"}
                deleteButtonText={"Confirm"}
                deleteMessage={
                  web
                    ? "You are about to remove this user from your site. Are you sure you want to do this? (This will not delete the WordPress user.)"
                    : "You are about to remove this user from your default site template. Are you sure you want to do this? (This will not delete the user from your account.)"
                }
              />
            );
          })}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
